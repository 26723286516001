const  router = [
	{
		path: "/deliver/layout",
		component: () => import('../../../pages/deliver/Layout.vue'),
		meta: {title: '客户管理'},
		name: 'deliver_layout',
		children:[
			{
				path: "/deliver",
				component: () => import('../../../pages/deliver/list.vue'),
				meta: {title: '全部'},
				name: 'deliver_list',
			},
			{
				path: "/deliver/deliver",
				component: () => import('../../../pages/deliver/deliver.vue'),
				meta: {title: '待交付'},
				name: 'deliver_deliver',
			},
			{
				path: "/deliver/modify",
				component: () => import('../../../pages/deliver/modify.vue'),
				meta: {title: '待修改'},
				name: 'deliver_modify',
			},
			{
				path: "/deliver/feedback",
				component: () => import('../../../pages/deliver/feedback.vue'),
				meta: {title: '待反馈'},
				name: 'deliver_feedback',
			},
			{
				path: "/deliver/information",
				component: () => import('../../../pages/deliver/information.vue'),
				meta: {title: '没资料'},
				name: 'deliver_information',
			},
			{
				path: "/deliver/part",
				component: () => import('../../../pages/deliver/part.vue'),
				meta: {title: '部分制作'},
				name: 'deliver_part',
			},
			{
				path: "/deliver/complete",
				component: () => import('../../../pages/deliver/complete.vue'),
				meta: {title: '已完成'},
				name: 'deliver_complete',
			},
		]
	}
];

export default router