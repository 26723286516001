const  router = [
	{
		path: "/account/layout",
		component: () => import('../../../pages/account/Layout.vue'),
		meta: {title: '账号管理'},
		name: 'account_layout',
		children:[
			{
				path: "/account",
				component: () => import('../../../pages/account/list.vue'),
				meta: {title: '账号列表'},
				name: 'account_list',
			},
			// {
			// 	path: "/article/detail",
			// 	component: () => import('../../../../pages/article/detail.vue'),
			// 	meta: {title: '文章'},
			// 	name: 'article_detail'
			// },
			// {
			// 	path: "/article/category",
			// 	component: () => import('../../../../pages/article/category.vue'),
			// 	meta: {title: '文章分类'},
			// 	name: 'article_category'
			// },
		]
	}
];

export default router