const  router = [
	{
		path: "/invoice/layout",
		component: () => import('../../../pages/invoice/Layout.vue'),
		meta: {title: '发票管理'},
		name: 'invoice_layout',
		children:[
			{
				path: "/invoice",
				component: () => import('../../../pages/invoice/list.vue'),
				meta: {title: '发票列表'},
				name: 'invoice_list',
			},
			// {
			// 	path: "/article/detail",
			// 	component: () => import('../../../../pages/article/detail.vue'),
			// 	meta: {title: '文章'},
			// 	name: 'article_detail'
			// },
			// {
			// 	path: "/article/category",
			// 	component: () => import('../../../../pages/article/category.vue'),
			// 	meta: {title: '文章分类'},
			// 	name: 'article_category'
			// },
		]
	}
];

export default router