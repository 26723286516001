export default {
    state: {
        accountInfo:localStorage.getItem('account-info') ? localStorage.getItem('account-info') : {mobile:'',name:'',face:'',role:0,num:0},
    },
    mutations: { 
        setAccountInfo(state,param){
            state.accountId = param;
            localStorage.setItem('account-id',param);
        }
    }
  }