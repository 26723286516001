const  router = [
	{
		path: "/version/layout",
		component: () => import('../../../pages/version/Layout.vue'),
		meta: {title: '版本管理'},
		name: 'version_layout',
		children:[
			{
				path: "/version",
				component: () => import('../../../pages/version/version.vue'),
				meta: {title: '版本列表'},
				name: 'version_list',
			},
			{
				path: "/version/template",
				component: () => import('../../../pages/version/template.vue'),
				meta: {title: '模板列表'},
				name: 'version_template'
			},
			{
				path: "/version/draft",
				component: () => import('../../../pages/version/draft.vue'),
				meta: {title: '草稿箱'},
				name: 'version_draft'
			},
		]
	}
];

export default router